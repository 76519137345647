import React, { useEffect, useState } from 'react';

import { Box, Typography, Grid } from '@mui/material';
import MainCard from 'components/MainCard';
import { debounce } from 'lodash';
import axiosInstance from 'utils/axios.config';
import branchLocation from '../../../../assets/images/image/branchLocation.png';
import Location from 'components/svg/Location';
export default function DoctorsAppointmentLists() {
  const [ListedAppoint, setListedAppoint] = useState([]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const parsedHours = parseInt(hours, 10);
    const suffix = parsedHours >= 12 ? 'PM' : 'AM';
    let convertedHours = parsedHours % 12;
    convertedHours = convertedHours === 0 ? 12 : convertedHours;
    return `${convertedHours}:${minutes} ${suffix}`;
  };

  useEffect(() => {
    const fetchData = debounce(() => {
      axiosInstance
        .get('https://api.hellokompass.com/drapplist')
        .then((res) => {
          setListedAppoint(res.data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, 100);

    fetchData();

    return () => {
      if (fetchData.cancel) {
        fetchData.cancel();
      }
    };
  }, []);

  return (
    <Box>
      <MainCard>
        <Box>
          <Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  fontSize: '23px',
                  color: '#0C1E21',
                  mb: '20px',
                  pb: '4px',
                  borderBottom: 1,
                  borderColor: '#f1f1f1'
                }}
              >
                Appointment List
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {ListedAppoint.map((listed) => (
                <Grid item xs={3}>
                  <Box sx={{ backgroundColor: '#0C1E21', display: 'flex', justifyContent: 'space-between', px: '10px', py: '5px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '13px', color: '#fff' }}>
                      {listed.appdate}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '13px', color: '#fff' }}>
                      {convertTo12HourFormat(listed.appsttime)} - {convertTo12HourFormat(listed.appendtime)}
                    </Typography>
                  </Box>
                  <Box sx={{ boxShadow: 2, px: '8px' }}>
                    <Box sx={{}}></Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Typography
                        sx={{ ml: '8px', pt: '4px', fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '15px', color: '#0C1E21' }}
                      >
                        Serial No : {listed.pt_serial}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                      <Box>
                        <img src={listed.drimage} alt="" style={{ width: '140px', height: '140px', borderRadius: '50%' }} />
                        <Box sx={{ mt: '20px' }}>
                          <Box sx={{ display: 'flex' }}>
                            <img src={branchLocation} alt="" style={{ width: '16px', height: '16px' }} />
                            <Typography sx={{ ml: '8px', fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                              {listed.branch_name}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', mt: '8px', pb: '4px' }}>
                            <Location></Location>
                            <Typography sx={{ ml: '8px', fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                              {listed.branch_address}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'start', pt: '40px' }}>
                        <Box>
                          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: '#0C1E21' }}>
                            {listed.drfname + ' ' + listed.drlname}
                          </Typography>
                          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                            {listed.dredudetails}
                          </Typography>
                          <Typography sx={{ fontWeight: 'semibold', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                            {listed.drpro_expe}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </MainCard>
    </Box>
  );
}
